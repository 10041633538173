
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { AuthProvider } from '~/context/AuthContext';
import { useValidation } from '~/hooks/validation';
import type { AppProps } from 'next/app';
import React, { ReactNode, useEffect, useState } from 'react';
import { setLocale } from 'yup';
import PiwikProProvider from '@piwikpro/next-piwik-pro';
import { PIWIK_CONTAINER_ID, PIWIK_CONTAINER_URL, ENABLED as PiwikEnabled } from 'config/piwik';
import { Provider } from 'react-redux';
import store from '~/store/index';
import OnboardingTest from '~/components/OnboardingTest/OnboardingTest';
import { Banner } from '~/components/Banner/Banner';
import useTranslation from 'next-translate/useTranslation';
import '../styles/globals.css';
import { useRouter } from 'next/router';
import useLocalStorage from '~/hooks/useLocalStorage';
import useAutoLogout from '~/hooks/useAutoLogout';
const Tracking: React.FC<{
    children: ReactNode;
}> = ({ children }) => {
    if (PiwikEnabled && PIWIK_CONTAINER_ID && PIWIK_CONTAINER_URL) {
        return (<PiwikProProvider containerUrl={PIWIK_CONTAINER_URL} containerId={PIWIK_CONTAINER_ID}>
        {children}
      </PiwikProProvider>);
    }
    return <>{children}</>;
};
function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    const { config } = useValidation();
    const { query, pathname } = useRouter();
    const [showSimulator, setShowSimulator] = useState(query.simulate === 'true');
    const { t } = useTranslation();
    // automatically logout when not active
    const { showLoggedOutMessage, setShowLoggedOutMessage } = useAutoLogout(60000);
    // shows/hides/ the simulator QA uses to simulate available loan amounts
    const localStorage1 = useLocalStorage('show-simulator');
    useEffect(() => {
        localStorage1.set(query.simulate);
        setShowSimulator(query.simulate === 'true');
    }, [query]);
    useEffect(() => {
        setLocale(config);
    }, []);
    return (<Tracking>
      <Provider store={store}>
        <AuthProvider>
          <div>
            <div className="text-base antialiased font-body">
              <Component {...pageProps}/>
            </div>
            <Banner id="auto-logout" open={pathname.includes('/auth') && showLoggedOutMessage} position="top" type="message" showOnce={false} autoClose={false} onClose={() => setShowLoggedOutMessage(false)}>
              <p>{t('cta:automatically-logged-out')}</p>
            </Banner>
          </div>
          {showSimulator === true && <OnboardingTest />}
          {/* <OnboardingTest /> */}
        </AuthProvider>
      </Provider>
    </Tracking>);
}
const __Next_Translate__Page__18afecae231__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18afecae231__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  